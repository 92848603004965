import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo } from 'react'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type {
  CustomCollectionPageQueryQuery,
  CustomCollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import RenderCMS from 'src/components/RenderCMS'
import { useInfoBar } from 'src/hooks/infoBar'
import type { InfoBarData } from 'src/hooks/infoBar/types'
import type { Facet } from '@faststore/sdk/dist/types'

import config from '../../store.config'

type Props = PageProps<
  CustomCollectionPageQueryQuery,
  CustomCollectionPageQueryQueryVariables
> & { slug: string }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    data: { cmsPlp },
  } = props

  const facets = cmsPlp?.config?.customPlp?.facets as Facet[]

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null
    const finalState = [...facets, ...(maybeState?.selectedFacets ?? [])]

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets: finalState && finalState.length > 0 ? finalState : [],
      term: maybeState?.term ?? null,
      sort: maybeState?.sort ?? 'score_desc',
    }
  }, [facets, href, pathname])
}

function Page(props: Props) {
  const {
    data: { site, cmsPlp },
    location: { pathname, search },
  } = props

  const searchParams = useSearchParams(props)

  const title =
    cmsPlp?.config?.customPlp?.title ?? site?.siteMetadata?.title ?? ''

  const pathnames = pathname
    .split('/')
    .filter((path) => String(path).trim())
    .join('/')

  const canonical = `${config.storeUrl}/${pathnames}${
    search.match(/page=\d/g)?.length
      ? `?${search.match(/page=\d/g)?.join('')}`
      : ''
  }`

  const page =
    search.match(/page=\d/g) &&
    search
      .match(/page=\d/g)
      ?.join('')
      .split('=')[1]

  const { setData } = useInfoBar()

  useEffect(() => {
    !!cmsPlp?.infoBar?.infoBar?.links?.length &&
      setData(cmsPlp.infoBar?.infoBar?.links as InfoBarData)
  }, [cmsPlp?.infoBar, setData])

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={cmsPlp?.config?.customPlp?.description ?? ''}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={
          page
            ? [
                {
                  rel: 'prev',
                  href: `${
                    parseInt(page, 10) - 1 === 0
                      ? `${config.storeUrl}/${pathnames}${search.replace(
                          /\?*&*page=\d*/g,
                          ''
                        )}`
                      : `${config.storeUrl}/${pathnames}${search.replace(
                          /page=\d*/g,
                          `page=${parseInt(page, 10) - 1}`
                        )}`
                  }`,
                },
                {
                  rel: 'next',
                  href: `${config.storeUrl}/${pathnames}${search.replace(
                    /page=\d*/g,
                    `page=${parseInt(page, 10) + 1}`
                  )}`,
                },
              ]
            : [
                {
                  rel: 'next',
                  href: `${config.storeUrl}/${pathnames}${search}?page=1`,
                },
              ]
        }
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: cmsPlp?.config?.customPlp?.slug ?? '',
            name:
              cmsPlp?.config?.customPlp?.title?.replace(
                site?.siteMetadata?.titleTemplate?.replace('%s', '') ?? '',
                ''
              ) ?? '',
            position: 1,
          },
        ]}
      />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <RenderCMS
        sections={cmsPlp?.sections}
        extraProps={{
          ProductGallery: { title },
          Breadcrumb: {
            breadcrumbList: [
              {
                item: cmsPlp?.config?.customPlp?.slug ?? '',
                name:
                  cmsPlp?.config?.customPlp?.title?.replace(
                    site?.siteMetadata?.titleTemplate?.replace('%s', '') ?? '',
                    ''
                  ) ?? '',
                position: 1,
              },
            ],
            name: title,
          },
          CategoryCarousel: {
            showPagination: false,
          },
        }}
      />
    </SearchProvider>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query CustomCollectionPageQuery($id: String!) {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }

    cmsPlp(id: { eq: $id }) {
      id

      config {
        customPlp {
          description
          facets {
            key
            value
          }
          slug
          title
        }
      }

      sections {
        data
        id
        name
      }

      infoBar {
        infoBar {
          links {
            bold
            hoverPanel {
              subLinks {
                highlighted
                link
                text
              }
              title
            }
            link
            text
            newTab
          }
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
